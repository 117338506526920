<div class="layout-topbar">
    <a class="layout-topbar-logo" >
        <img src="./assets/layout/images/logo_futhunter.jpeg" alt="logo">
        <span>FutHunter1</span>
    </a>


    <a class="p-link layout-topbar-menu-button layout-topbar-button" >
        <i class="pi pi-ellipsis-v"></i>
    </a>

    <div class="layout-topbar-menu" [ngClass]="{'layout-topbar-menu-mobile-active':appMain.topMenuActive}">
        <a  class="p-link layout-topbar-button">
            <i class="pi pi-user"></i>
            <span>Profile</span>
        </a>
    </div>
</div>
